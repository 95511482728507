import React, { useEffect, useLayoutEffect, useState } from 'react'
import { SearchOutline } from 'antd-mobile-icons'
import { Link } from 'react-router-dom'
import useActiveState from '../../../lib/useActiveState'
import Manager from '../Manager'
import './css/css.css'

export default function HomeSearch() {

  const [icon, setIcon] = useState(<SearchOutline />)
  const [placeholder, setPlaceholder] = useState('')
  const [config] = useActiveState('config')
  const [index,setIndex] = useState(0)

  useLayoutEffect(()=>{
    if(config) {
      if (config.app_home_search_default_title_list?.length > 0){
        setPlaceholder(config.app_home_search_default_title_list[index])
      }
      if (config.app_home_search_default_title_list?.length > 0){
        setTimeout(() => {
          if (index == config.app_home_search_default_title_list.length-1){
            setIndex(0)
          }else {
            setIndex(e=>e+1)
          }
        }, 2000);
      }
    }
  },[config,index])

  return (
    <Link to="/search" onClick={()=>{
      Manager.push({ event_id:'B-1' })
    }}>
      <div className='index-home-search'>
        <img src="/resources/user/ss.png" className="icon" />
        <span className='text'>{placeholder}</span>
      </div>
    </Link>
  )
}

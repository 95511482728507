import React, {useEffect, useState} from "react";
import general from "../../general";
import GiftDetail from "../gift/Detail";
import $ from "jquery";
import {CloseOutlined, ContactsOutlined, SearchOutlined} from "@ant-design/icons";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {useScroll} from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

export default props => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [kw, setKw] = useState('');
  const [gifts, setGifts] = useState([]);
  const [more, setMore] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      search_info: kw,
      pagination: {page: page},
    };
    if (parms.page) {
      tmp.pagination = {page: parms.page};
    }
    general.axios.post('base/gift/getMyGiftList', tmp)
        .finally(() => {
          setLoading(false);
          Dialog.close();
        })
        .then(response => {
          setInit(true);
          let result = response.data.data;
          if (parms.page) {
            setPage(parms.page);
          }
          setGifts(gifts => parms.page === 1 || !parms.page ? result.gift_list : [...gifts, ...result.gift_list]);
          setMore(result.paginated.more && result.gift_list.length > 0);
          setLoaded(true);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);
  useEffect(() => {
    //改成 kw不为空时在调用
    if(kw) {
      load({page: 1});
    }
  }, [kw]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  const [gift, setGift] = useState(null);

  //搜索处理
  const [inputFlag, setInputFlag] = useState(false);
  const [inputText, setInputText] = useState('');
  let isOnComposition = false;

  const handleComposition = (e) => {
    if (e.type === 'compositionend') {
      const value = e.target.value;

      setInputFlag(false);
      isOnComposition = false;
      setInputText(e.target.value);
      setKw(e.target.value);
      return;

    } else if (e.type === 'compositionstart') {

      isOnComposition = true;
      setInputFlag(true);
      return;
    } else if (e.type === 'compositionupdate') {
      isOnComposition = true;
      setInputFlag(true);
      return;
    }
    
    isOnComposition = true;
  }

  const handleChange = (e) => {
    setInputText(e.target.value);
    if (!isOnComposition) {
      // setInputText(e.target.value);
      setKw(e.target.value)
    }
  }

  const [giftDetail, setGiftDetail] = useState(null);

  const giftShowNewWindow = item => {
    Dialog.loading();

    general.axios.post('/base/gift/getGiftDetail', {gift_id: item.gift_id})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        let descData = data.gift_info;
        setGift(data.gift_info);
        // setGiftDescInfoImage(descData.new_image.thumb);
        // setGiftShow(true);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  return <div className="user-gift">
    <div className="user-gift-head">
      <div className="input">
        {/* <SearchOutlined className="icon"/> */}
        <img src="/resources/user/ss.png" className="icon" />
        {/* <input type="text" value={kw} placeholder="输入关键词搜索" onChange={e => setKw(e.target.value)}/> */}
        <input type="text" value={inputText || ''} placeholder="输入关键词搜索"
            onCompositionStart = {handleComposition}
            onCompositionUpdate = {handleComposition}
            onCompositionEnd = {handleComposition}
            onChange = {handleChange}
            />
        {kw || inputText ? <CloseOutlined className="close" onClick={() => {setKw('');setInputText('')}}/> : null}
      </div>
    </div>
    <div className="user-gift-body">
      {gifts.map(gift => <div className="item" onClick={() => giftShowNewWindow(gift)}>
        <div className="icon"><Image src={gift.gift_image.thumb}/></div>
        <div className="text">
          <div className="name">
            {/* {gift.game_name} */}
            <div className={" " + (gift.nameRemark ? " name-new" : "")}>
              {gift.game_name}
            </div>
            {gift.nameRemark != '' ? <div className="nameRemark-new">{gift.nameRemark}</div> : null}
          </div>
          <div className="gift-name">名称：{gift.gift_name}</div>
          <div className="desc">内容：{gift.gift_desc}</div>
          <div className="code">礼包码：{gift.gift_code}</div>
        </div>
        <CopyToClipboard text={gift.gift_code} onCopy={() => Dialog.info('已复制')}>
          <div className="btn" onClick={e => e.stopPropagation()}>复制</div>
        </CopyToClipboard>
      </div>)}
      {/* <div className={'pull-to-load ' + (!more ? 'nomore' : '')}/> */}
      {gifts.length === 0 && loaded ? <div className="recovery-empty"><img src="/resources/user/gift_empty.png" alt=""/>暂无礼包，快去游戏详情页领取吧~</div> : null}
    </div>
    <GiftDetail gift={gift} setGift={setGift} copyShow={false}/>
    {gifts.length > 0 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
  </div>;
};
import { useEffect, useState } from "react";
import general from "../general";
import { Link } from "react-router-dom";
import { useReactive, useScroll } from "ahooks";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Dialog from "../lib/Dialog";
import Game from "./credit/Game";
import useActiveState from "../lib/useActiveState";
import { RightOutlined } from "@ant-design/icons";
import URI from "urijs";

export default props => {
  const [init, setInit] = useState(false);
  const [data, setData] = useState({})
  const [mb] = useActiveState('mb')

  const load = () => {
    Dialog.loading();
    general.axios.post('/base/kefu/indexv3')
      .finally(() => {
        Dialog.close();
      })
      .then(response => {
        setInit(true);
        let result = response.data.data;
        setData(result)
        // general.faqGroups = result.faq;

      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active, init]);

  const scroll = useScroll()
  useEffect(() => {
    if (props.active) {
      const element = document.querySelector('.container')
      const value = scroll.top
      if (value <= 0) {
        props.setColour('#ffffff00')
      } else {
        if (value < 128) {
          props.setColour(`#ffffff${parseFloat(value * 2).toString(16)}`)
        } else {
          props.setColour('#fff')
        }
      }
    }
  }, [scroll, props.active])

  //   const downFiles = () => {
  //     // 创建隐藏的可下载链接
  //     var eleLink = document.createElement('a');
  //     eleLink.download = "vip.png";
  //     eleLink.style.display = 'none';
  //     // 字符内容转变成blob地址
  //     var blob = new Blob([data.kefu_weixin_qrcode]);
  // 　　 // 简单的理解一下就是将一个file或Blob类型的对象转为UTF-16的字符串，并保存在当前操作的document
  //     eleLink.href = URL.createObjectURL(blob);
  //     // 触发点击
  //     document.body.appendChild(eleLink);
  //     eleLink.click();

  //     setTimeout(()=>{
  //       window.location.href = 'weixin://';
  //     }, 3000)
  //     // 然后移除
  //     document.body.removeChild(eleLink);
  //   }
  const qqHref = () => {
    // window.location.href = 'mqqwpa://im/chat?chat_type=wpa&uin=' + data.qq + '&version=1&src_type=web&web_src=qq.com';
    // setTimeout(()=>{
    //   window.location.href = 'tim://im/chat?chat_type=wpa&uin=' +data.qq + '&version=1&src_type=web&web_src=qq.com';
    // }, 1000)
  }

  const qqGroupHref = () => {
    window.location.href = 'mqqwpa://im/chat?chat_type=wpa&uin=' + data.qq_group + '&version=1&src_type=web&web_src=qq.com';
    window.location.href = 'tim://im/chat?chat_type=wpa&uin=' + data.qq_group + '&version=1&src_type=web&web_src=qq.com';
  }

  return <div
    className="service-index"
    style={{
      backgroundImage: 'url(/resources/service/bg.png)',
      backgroundSize: '100% 378px',
      backgroundRepeat: 'no-repeat'
    }}
  >
    <div className="service-title">客服中心</div>
    {init && <div className="service-container">
      <div className="service-time">
        <div className="time-title">Hi~ 有什么可以帮您的！</div>
        <div className="time-desc">在线时间：{data.kefu_info?.online_time}</div>
      </div>

      {data.kefu_info?.kefu_weixin_title && <div className="service-account">
        <div className="service-info">
          <div className="service-text">关注微信公众号</div>
          <div className="service-desc">联系在线客服，订阅更多福利消息！</div>
        </div>
        <CopyToClipboard text={data.kefu_info.wechat_public_number_title} onCopy={() => Dialog.info('已复制公众号', () => window.location.href = 'weixin://')}>
          <div className="service-coll">去关注</div>
        </CopyToClipboard>
      </div>}

      <div className="infos">
        {data.kefu_data?.map((item, index) => {
          return (
            <div key={index} className="item" onClick={() => {
              let url = ""
              if (item.type == 'qq_group') {
                url = `mqqapi://card/show_pslcard?src_type=internal&version=1&uin=${item.value}&key=${item.key}&card_type=group&source=external`
              } else if (item.type == 'qq_private') {
                url = item.url
              } else if (item.type == 'qq') {
                url = `mqqwpa://im/chat?chat_type=wpa&uin=${item.value}&version=1&src_type=web&web_src=oicqzone.com`
              } else if (item.type == 'kefu_weixin_private') {
                url = item.url
              } else if (item.type == 'kefu_weixin') {
                url = item.url
              } else if (item.type == 'email') {
                url = 'mailto:' + item.value
              } else if (item.type == 'line') {
                url = item.url
              } else if (item.type == 'facebook') {
                url = item.url
              }
              if (mb == 1) {
                      let hrefUrl = URI('app://browser')
                        .addQuery('url', URI(url)
                        )
                        .toString();
                window.location.href = hrefUrl;
              } else {
                window.location.href = url;
              }
            }}>
              <img className="icon" src={item.icon} alt="" />
              {item.value.length > 0 ? 
                <CopyToClipboard text={item.value} onCopy={() => Dialog.info('已复制')}>
                  <div className="content">
                    <div className="text">{item.title}</div>
                    {item.value && <div className="value">{item.value}</div>}
                  </div>
                </CopyToClipboard> :
                <div className="content">
                  <div className="text">{item.title}</div>
                  {item.value && <div className="value">{item.value}</div>}
                </div>
              }
              <RightOutlined className="right"></RightOutlined>
            </div>
          )
        })}
      </div>

      <div className="contents">
        <div className="header">快捷服务</div>
        <div className="container">
          <div className="service">
            {data.quick_service?.map((item, index) => {
              return (
                <div className="item" key={index} onClick={() => {
                  general.history.push({
                    pathname: `/faq?id=${item.id}&type=1`,
                    title: item.title
                  })
                }}>
                  <img className="icon" src={item.icon} alt="" />
                  <div className="text">{item.title}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className="contents">
        <div className="header">常见问题</div>
        <div className="container">
          <div className="question">
            {data.frequently_question?.map((item, index) => {
              return (
                <div key={index} onClick={() => {
                  general.history.push({
                    pathname: `/faq?id=${item.id}&type=2`,
                    title: item.q
                  })
                }} className="item" style={{ borderBottom: (index == data.frequently_question?.length - 1 ? 'none' : '1px solid #EDEDED') }}>
                  <div>{item.q}</div>
                  <RightOutlined style={{ color: '#dedede', fontSize: '10px', paddingRight: '10px' }}></RightOutlined>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className="mobile">
        <div>
          <span className="key">投诉电话</span>
          <span className="value">{data.complaint_tel}（仅处理投诉问题）</span>
        </div>
        <div onClick={() => window.location.href = 'tel:' + data.complaint_tel} className="tel">拨打</div>
      </div>

    </div>}

    {/***弹框 */}
    {/* {custromState ?
    <div className="wechat-pic-index">
      <div className="wechat-pic-site">
        <div className="wechat-pic-main">
          <div className="close" onClick={()=>setCustromState(false)}><img src="/resources/user/close.png" /></div>
          <div className="wechat-pic-title">
            添加企业微信
          </div>
          <div className="qrcode">
            <img src={data.kefu_weixin_qrcode} />
          </div>
          <div className="desc">
            手动截屏保存二维码至相册--点击微信右上角“+”扫一扫--进入相册选择二维码照片--添加至通讯录即可
          </div>
          <div className="btn">

            <a href="weixin://" className="vip-b">前往微信添加</a>
          </div>
        </div>
      </div>
    </div>
    :null
    } */}

  </div>;
};
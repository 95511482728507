import React, {useEffect, useState} from "react";
import {ShareAltOutlined, StarOutlined, StarFilled} from "@ant-design/icons";
import general from "../../general";
import moment from "moment";
import {Link} from "react-router-dom";
import {NavLink} from "../../lib/Navigation";
import useActiveState from "../../lib/useActiveState";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import {CSSTransition} from "react-transition-group";
import MobileDetect from "mobile-detect";
import useUrlState from "@ahooksjs/use-url-state";
import {useLockFn} from "ahooks";
import Subtitle from "../components/Subtitle";

const devices = {
  '0': '双',
  '1': '安卓',
  '2': '苹果',
};

const status = {
  '0': 'verify',
  '-1': 'invalid',
  '1': 'complete',
  '2': 'reject',
  '3': 'reviewed',
};

const Operates = props => {
  const [trade, setTrade] = useState({});
  useEffect(() => {
    setTrade(props.trade);
  }, [props]);
  {/*const favorite = () => {
    Dialog.loading();
    general.axios.post(trade.is_collected ? '/cancelCollectTrade' : '/collectTrade', {id: trade.trade_id})
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            setTrade({...trade, is_collected: !trade.is_collected});
            Dialog.info('操作成功');
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }*/}
 
  // console.log(user)
  // console.log(user.agent)
  const [params] = useUrlState({}, {navigateMode: 'replace'});
  const [user] = useActiveState('user');
  const [shareUrl, setShareUrl] = useState(0);
  const [shAgent] = useActiveState('agent');

  useEffect(() => {
    let shareAgent = user.isLogin ? user.agent : shAgent;
    let url = window.location.href;
    // let url = "https://webbox.99maiyou.cn/#/trade/detail?id="+ params.id +"&agent=" + shareAgent;
    if(window.location.host == 'webbox.99maiyou.cn') {
      if(params.agent) {
        url = window.location.href;
      } else {
        url = window.location.href + "&agent=" + shAgent;
      }
    }
    setShareUrl(url);
  }, [params])
  
  return <div className="trade-operates">
    {/* {trade.is_collected
        ? <StarFilled className="trade-operate" onClick={favorite}/>
        : <StarOutlined className="trade-operate" onClick={favorite}/>
    } */}
    <CopyToClipboard text={shareUrl} onCopy={() => Dialog.info('已复制链接')}>
      <ShareAltOutlined className="trade-operate"/>
    </CopyToClipboard>
  </div>
}

export default props => {
  const {aplus_queue} = window;
  const [user, setUser] = useActiveState('user');
  const [trade, setTrade] = useState({});
  const [game, seGame] = useState({});
  const [loading, setLoading] = useState(false);
  const [agent] = useActiveState('agent');
  const [isCheckAdult, setIsCheckAdult] = useState(false); //未成年温馨提示状态
  const [isCheckAuth, setIsCheckAuth] = useState(false); //实名认证弹框状态
  const [authData, setAuthData] = useState({}); //实名认证参数值
  const [config] = useActiveState('config');

  useEffect(() => {
    props.setOperate(<Operates trade={trade ? trade : {trade_id: props.id}}/>);
  }, [props.id, trade]);
  const load = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    general.axios.post('/user/trade/getTradeDetail', {trade_id: props.query.id})
        .finally(() => {
          setLoading(false);
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            setTrade({...data, is_collected: parseInt(data.is_collected)});
            seGame(data.game_info);
            
            //埋点
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['ClickTradeGameDetails', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), gamename:data.game_info.game_name}]
            });
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    load();
  }, []);

  const [confirm, setConfirm] = useState();
  const [confirmed, setConfirmed] = useState();
  const [change, setChange] = useState();
  const mobileDetect = new MobileDetect(navigator.userAgent);

  useEffect(() => {
    if (confirm) {
      if (mobileDetect.is('iPhone')) {

      } else {
        // setConfirm(false);
        // window.location.href="https://app.milu.com/?a=" + agent;
      }
    } else {
      setConfirm(false);
    }
  }, [confirm]);

  //立即购买
  const buyNow = useLockFn(()=>{
    if(user.isCheckAuthTrade && !user.isRealNameAuth) {
      setIsCheckAuth(true);
      return;
    }
    if(user.isCheckAdultTrade && !user.isAdult) {
      setIsCheckAdult(true);
      return;
    }

    if(mobileDetect.os() == 'AndroidOS' && trade.game_device_type == '2') {
      Dialog.dialog({
        type: 'modal',
        mask: true,
        content: '该账号角色为苹果设备账号 安卓设备可能无法登录使用，您确定要购买吗？',
        btns: [
          {
            text: '取消', onPress: () => {
              // Dialog.info(mobileDetect.os() == 'AndroidOS');
            }
          },
          {
            text: '继续购买', onPress: () => {
              setConfirm(true);
              setChange(false);
            }
          }
        ]
      });
      return;
    }

    if(mobileDetect.os() == 'iOS' && trade.game_device_type == '1') {
      Dialog.dialog({
        type: 'modal',
        mask: true,
        content: '该账号角色为安卓设备账号 苹果设备可能无法登录使用，您确定要购买吗？',
        btns: [
          {
            text: '取消', onPress: () => {
              // Dialog.info(mobileDetect.os() == 'AndroidOS');
            }
          },
          {
            text: '继续购买', onPress: () => {
              setConfirm(true);
              setChange(false);
            }
          }
        ]
      });
      return;
    }
    
    setConfirm(true);
    setChange(false);
  });

  //立即换购
  const buyImmed = useLockFn(()=>{
    if(user.isCheckAuthTrade && !user.isRealNameAuth) {
      setIsCheckAuth(true);
      return;
    }
    if(user.isCheckAdultTrade && !user.isAdult) {
      setIsCheckAdult(true);
      return;
    }
    setConfirm(true);
    setChange(true);
  })


   //实名认证
  const certifiy = useLockFn(()=>{
    if(!authData.real_name) {
      Dialog.info('请输入姓名');
      return;
    }
    if(!authData.identity_card){
      Dialog.info('请输入身份证号');
      return;
    }
    Dialog.loading();
    general.axios.post('/user/user/editMemberInfo', {real_name:authData.real_name, identity_card:authData.identity_card})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          Dialog.info("认证成功");
          setIsCheckAuth(false);
          setUser({...user, ...{real_name: authData.real_name, identity_card: authData.identity_card, isRealNameAuth: true, isAdult: data.isAdult}});
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
    })

  //收藏与取消收藏
  const favorite = () => {
      Dialog.loading();
      general.axios.post(trade.is_collected ? '/cancelCollectTrade' : '/collectTrade', {id: trade.trade_id})
          .finally(() => {
            Dialog.close();
          })
          .then(res => {
            let {status} = res.data;
            if (!status.succeed) {
              Dialog.error(status.error_desc);
            } else {
              setTrade({...trade, is_collected: !trade.is_collected});
              Dialog.info('操作成功');
            }
          })
          .catch(err => {
            Dialog.error(err.message);
          });
    }
    // console.log(trade);
// console.log(game);
  return <div><div className="trade-detail" style={{display:(Object.keys(trade).length > 0 ? '' : 'none')}}>
      <div className="trade-body">
        {/* <Link to={'/trade/detail?id=' + trade.trade_id} className="trade-game"> */}
        <Link to={'/game?id=' + game.game_id} className="trade-game">
          <div className="icon"><Image src={game.game_image?.thumb}/></div>
          <div className="text">
            <div className="name">
              {/* {game.game_name} */}
              <div className={" " + (game.nameRemark ? " name-new" : "")}>
                {game.game_name}
              </div>
            </div>
            <div className="info">
              {/* {
                game.nameRemark ?
                <span className="nameRemark">
                  {game?.nameRemark}&nbsp;
                </span>
                : null
              } */}
              {/* {game.game_classify_name?.map(item => <span>{item.tagname} </span>)} */}
              {game.game_classify_name?.map((item,i) => <span>{item.tagname}{(game.game_classify_name.length - 1 == i) ? '' : ' '}</span>)}
              <Subtitle>{game.nameRemark}</Subtitle>
              {/* <span>{game.game_download_num}次下载</span>
              <span>{game.game_size?.ios_size}</span> */}
            </div>
            
            {/* <div className="info2">
              {game.game_desc ? game.game_desc.split('+').map(item => <div
                className="tag">{item}</div>) : game.introduction}
            </div> */}
          </div>
          <div className="btn">{game.game_species_type == 3 ? "开始" : "下载"}</div>
        </Link>
        <div className="trade-item">
          <div className="head">基本信息<span>（适用于{devices[trade.game_device_type]}端）</span></div>
          <div className="body base">
            <div className="info">
              {
                trade.check_status == 1 ?
                <div className="item">上架时间：{moment(trade.trade_time * 1000).format('YYYY-MM-DD HH:mm:ss')}</div>
                :
                <div className="item">上架时间：{moment(trade.sell_time * 1000).format('YYYY-MM-DD HH:mm:ss')}</div>
              }
              {/* <div className="item">上架时间：{moment(trade.sell_time * 1000).format('YYYY-MM-DD HH:mm:ss')}</div> */}
              <div className="item">小号：{trade.xh_alias}</div>
              <div className="item">区服：{trade.server_name}</div>
              <div className="item">价格：<span className="price">{trade.sell_price}</span> {trade.coin > 0 ? <span className="coin">购买可得{trade.coin}金币</span> : null}</div>
              {/* <div className="item">备注：此号已创建{Math.ceil((moment().valueOf() / 1000 - trade.xh_create_time) / 86400)}天，
          实际累充{trade.xh_recharge_money}元</div> */}
              <div className="item">备注：{trade.msg}</div>
              <div className="trade-state">
                <img src={'/resources/trade/' + status[trade.check_status] + '.png'} alt=""/>
              </div>
            </div>
            {/* <div className="price">￥{trade.sell_price}</div> */}
          </div>
        </div>
        {/* <div className="trade-desc">
          此号已创建{Math.ceil((moment().valueOf() / 1000 - trade.xh_create_time) / 86400)}天，
          实际累充{trade.xh_recharge_money}元
          <div className="trade-state">
            <img src={'/resources/trade/' + status[trade.check_status] + '.png'} alt=""/>
          </div>
        </div> */}
        {
          trade.check_status == 2 ? 
          <div className="trade-item">
            <div className="head">
              审核失败
            </div>
            <div className="body desc">
              {trade.reject_content}
            </div>
          </div>
          :null
        }
        
        <div className="trade-item">
          <div className="head">{trade.title}</div>
          {trade.type == 1 ?
          <>{trade.content ?
          <div className="body desc" dangerouslySetInnerHTML={{__html: trade.content ? trade.content.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2') : null}}/>
          : null }</>
          :<>
          {trade.content_html ?
          <div className="body desc" dangerouslySetInnerHTML={{__html: trade.content_html ? trade.content_html.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2') : null}}/>
          : null }
          </>}
        </div>
        <div className="trade-item">
          <div className="head">游戏截图</div>
          <div className="body imgs clearfix">
            {trade.game_screenshots?.map((img, index, imgs) => <Link to={'/img?imgs=' + imgs.map(item => item.source) + '&img=' + index} className="img"><Image src={img.thumb} alt=""/></Link>)}
          </div>
        </div>
        {/* <div className="view-end">别撩啦！已经到底啦~</div> */}
      </div>
      {(trade.check_status == 3 || trade.check_status == 2) && trade.seller_username != user.member_name ? 
      <div className="trade-operate">
        <div className="container">
          {/* <NavLink className={'favorite ' + (trade.is_collected ? 'active' : '')} >
            <StarOutlined className="icon favorite"/>
            <StarFilled className="icon favorited"/>
            收藏
          </NavLink> */}
          {/* <div className="btn" onClick={buyNow}>立即购买
          </div> */}
          {/* {trade.is_change ? <div className="btn" onClick={buyImmed}>立即换购</div> : null} */}
        </div>
        <NavLink className={'favorite ' + (trade.is_collected ? 'active' : '')} onClick={favorite}>
          <img src="/resources/trade/collected.png" className="icon favorited"/>
          <img src="/resources/trade/cancelcollected.png" className="icon favorite"/>
            {/* <StarOutlined className="icon favorite"/>
            <StarFilled className="icon favorited"/> */}
            收藏
        </NavLink>
        {
          trade.check_status == 2 ? 
          <NavLink to={'/trade/submit?id=' + trade.trade_id} className="btn" >重新出售</NavLink>
          :
          <div className="btn" onClick={buyNow}>立即购买</div>
        }
        {/* <div className="btn" onClick={buyNow}>立即购买</div> */}
      </div> : null}
      <CSSTransition in={confirm} timeout={250} unmountOnExit={true}>
        <div className="trade-confirm">
          <div className="container" style={{backgroundImage: 'url(/resources/trade/confirm-bg.png)'}}>
            <div className="head">
              <div className="title">买家必读</div>
              <div className="info">为了您的消费权益，请仔细阅读</div>
            </div>
            <div className="main">
              <div className="title"><img src="/resources/trade/title-1.png" alt=""/>购买流程</div>
              <div className="process">
                <img src="/resources/trade/process.png" alt=""/>
                <ul className="clearfix">
                  <li>选择商品</li>
                  <li>购买商品</li>
                  <li>下载游戏</li>
                  <li>登录游戏</li>
                </ul>
              </div>
              <div className="title"><img src="/resources/trade/title-2.png" alt=""/>买家须知</div>
              <div className="body">
                <p>角色描述、截图已通过官方核验。因时间因素造成的变化(排行榜、称号、装备到期等)，不视为信息失实。</p>
                <p>交易后角色直接转入您购买所使用的账号，登录游戏即可查收角色。</p>
                <p>交易过程仅限小号转移不涉及账号交易或换绑操作，无需担心购买的号被找回。</p>
                <p>购买时显示累计金额为该小号在该游戏同一小号下多个区服的角色所有累计充值。</p>
                <p>交易完成后，不支持退货。</p>
                <p>交易完成的小号超过72小时后才可重新上架交易。</p>
                <p>所购买的小号不支持转游/转区操作。</p>
                <p>购买的账号再回收仅计算购买后充值的部分</p>
                <p>买家须知:因买家购买过程中操作失误产生的一切损失，由买家自行承担，平台概不负责。</p>
              </div>
            </div>
            <div className="operate">
              <div className="input"><input type="radio" onChange={() => setConfirmed(true)}/>我已阅读</div>
              <NavLink
                className={'buy' + (!confirmed ? ' disable' : '')}
                to={'//sys.wakaifu.com/home/trade?id=' + trade.trade_id + '' + (change ? '&type=2' : '')+'&from=react'}
                onClick={e => {
                  if (!confirmed) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                  }
                  setConfirm(false);
                  setConfirmed(false);
                }}
                iframe={{title: '购买'}}>立即{change ? '换购' : '购买'}</NavLink>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>

    {/***实名认证弹框***/}
    {isCheckAuth?
    <div className="pay-check-auth">
        <div className="auth-index">
          <div className="close" onClick={() => setIsCheckAuth(false)}>
            <img src="/resources/user/close.png" />
          </div>
          <div className="title">
            <div className="title-img">
              <img src="/resources/user/auth-title.png" />
            </div>
            <div>
              <h3>实名认证</h3>
              <p>
              {config.authTopTips}
                {/* 根据国家新闻出版署最新规定，游戏用户需要进行实名认证，否则我们无法为您提供游戏及支付服务！ */}
              </p>
            </div>
          </div>

          <div className="auth-user-realname">
            <div className="auth-user-input">
              <div className="name">真实姓名</div>
              <input name="nick_name" type="text" placeholder="请输入姓名" autocomplete="off" value={authData.real_name} onChange={e => setAuthData({...authData, real_name: e.target.value})} />
            </div>
            <div className="auth-user-input">
              <div className="name">身份证号</div>
              <input name="number" type="text" placeholder="请输入身份证号" autocomplete="off" value={authData.identity_card} onChange={e => setAuthData({...authData, identity_card: e.target.value})} />
            </div>
            <span>
            {config.authBottomTips}
            {/* 您所提交的身份信息仅用于国家认证，我们将按照国家隐私条例严格保密信息，感谢您的支持！ */}
            </span>
            <div className="auth-user-btn" onClick={certifiy}>立即认证</div>
          </div>
        </div>
      </div> : null
      }
      {/** 提示弹框 **/}
      {
      isCheckAdult ? 
      <div className="pay-check-auth">
        <div className="auth-index adult-index">
          <div className="title">
            <div className="title-img">
              <img src="/resources/user/auth-title.png" />
            </div>
            <div>
              <h3>温馨提示</h3>
              <p>
              {config.adultTips}
                {/* 根据国家新闻出版署最新规定，禁止向未成年用户提供支付服务。 */}
              </p>
            </div>
          </div>
          <div className="auth-user-realname">
            <div className="auth-user-btn" onClick={()=>setIsCheckAdult(false)}>我知道了</div>
          </div>
        </div>
      </div>
      : null
      }
  </div>;
};
import {SearchOutlined} from '@ant-design/icons';
import {useEffect, useRef, useState, useCallback} from "react";
import New from "./New";
import Good from "./Good";
import URI from "urijs";
import Rank from "./Rank";
import Single from "./Single";
import Project from './Project';
import Active from './Active';
import {Link, useHistory, useLocation} from "react-router-dom";
import {useLocalStorageState, useClickAway} from "ahooks";
import moment from "moment";
import useActiveState from '../../lib/useActiveState';
import general from "../../general";
import $ from "jquery";
import Video from './Video';
import Beta from '../party/Beta';
import KeepScroll from "../../lib/KeepScroll";
import NewGame from './newgame/NewGame';
import Dialog from '../../lib/Dialog'
import PubSub from 'pubsub-js';
import { NavLink } from '../../lib/Navigation';
import Manager from '../components/Manager';

// const router = [
//   {
//     component: Good,
//     title: '精选',
//     invasion: true,
//     type: 'recommend'
//   },
//   {
//     id: 'new',
//     component: New,
//     title: '新游',
//   },
//   {
//     component: Video,
//     title: '视频',
//   },
// ];


const router = [
  {
    // title: "视频",
    // is_pic: false,
    // type: 'video',
    // invasion:false,
  },
  // {
  //   component: New,
  //   title: '新游甄选',
  //   is_pic: true,
  // },
  // {
  //   component: Rank,
  //   title: '排行榜',
  //   is_pic: false,
  // },
  // {
  //   component: New,
  //   title: '十倍道具',
  //   is_pic: true,
  // },
];

export default props => {
  const {aplus_queue} = window;
  // const [routerIndex, setRouterIndex] = useLocalStorageState('index-index', 1);
  // const [routerIndex, setRouterIndex] = useState(1);
  const location = useLocation();
  const history = useHistory();
  const [routerIndex, setRouterIndex] = useState(parseInt(props) || 0);
  const [badge, setBadge] = useLocalStorageState('good-type-badge-' + moment().format('YYYY-MM-DD'), {});
  const [opacity, setOpacity] = useState();
  const [newopacity, setNewOpacity] = useState(0);
  const [travelopacity, setTravelOpacity] = useState(0);
  const [operate, setOperate] = useState(null);
  const [config] = useActiveState('config');
  // const [navList, setNavList] = useLocalStorageState('navlist-index', []);
  const [navLists, setNavLists] = useActiveState('navLists');
  const [newNavLists, setNewNavLists] = useLocalStorageState('index-navlists', []);
  const [topMenus, setTopMenus] = useLocalStorageState('index-top-menus', router);
  // const [topMenus, setTopMenus] = useState([]);
  const [navTitle, setNavTitle] = useState('recommend');
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');
  // const [indexNumberState, setIndexNumberState] = useActiveState('indexNumberState');

  // console.log(config.navList);
  // let [app] = useActiveState('app');
  // console.log(app);
  const ref = useRef(null);
  useClickAway(ref, () => {
    setOperate(false);
  });
  // useEffect(()=>{
  //   setRouterIndex(1);
  // },[app])



  //new-config 20220816新增--为解决导航不及时更新的问题
  const navConfig = () => {
    general.axios.post('/base/common/getConfig')
      .then(res => {
        let {status, data} = res.data;
        if (status.succeed) {
          let arr1 = [];
          for(let i = 0; i < data.navList.length; i++){
            arr1.push(data.navList[i]);
          }

          setNewNavLists(arr1);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }
  
  useEffect(()=>{
    navConfig();
  }, [agent]);

  // console.log(newNavLists);
  // console.log(newNavLists);
  useEffect(() => {
    // console.log(newNavLists.length);
    if(newNavLists.length > 0) {
      let menus = [...router];
      newNavLists.forEach((item, index) => {
        let components;
        let invasion;
        let is_pic;
        if(item.type == 'goodSelected') {
          invasion = true;
        } else if(item.type == 'recommend') {
          invasion = true;
        } else if(item.type == 'topGame') {
          invasion = false;
        } else if(item.type == 'special') {
          invasion = false;
        } else if(item.type == 'newGame') {
          invasion = true;
        } else if(item.type == 'oneGame') {
          invasion = true;
        } else {
          invasion = false;
        }

        if(item.big_title_img == "") {
          is_pic = false;
        } else {
          is_pic = true;
        }
        menus.splice(-1,0, {
          component: components,
          title: item.title,
          is_pic: is_pic,
          big_title_img: item.big_title_img,
          type:item.type,
          invasion:invasion,
          value:item.value,
          style:item.style,
          needlogin:item.needlogin,
          event_id:item.event_id
        })
      });
      // console.log(menus);
      setNavLists(menus);
      setTopMenus(menus);
    }
  }, [newNavLists]);

  // console.log("123");
  // console.log(newNavLists);

// console.log(opacity);
// console.log(topMenus);

  const reHrefLoad = item => {
    // if(item.type == 'special') {
    //   history.push('/game/project?id=' + item.value);
    // } else 
    console.log('aaa',item);
    if(item.type == 'active') {
      if(!general.isLogin()) {
        // history.push('/login');
        // PubSub.publish('alert-login',true)
      } else {
        // let to;
        // let url = URI(item.value)
        //       .addQuery('username', localStorage.getItem('username'))
        //       .addQuery('token', localStorage.getItem('token'))
        //       .addQuery('location_url', window.location.origin)
        //       .addQuery('origin', 'react')
        //       .toString();
        
        // history.push(to);
        // setRouterIndex(index);
        // setNavTitle(item.type);
      }
    }
  }
  // console.log(navTitle);
  // console.log(topMenus.length);
  // console.log(newopacity);

  const tagRef = useCallback(node => {
    if (node) {
      if (
        node.parentNode.scrollLeft + node.parentNode.offsetWidth < node.offsetLeft
        || node.parentNode.scrollLeft + node.parentNode.offsetWidth + node.offsetWidth > node.offsetLeft
      ) {
        $(node.parentNode).animate({scrollLeft: node.offsetLeft - node.parentNode.offsetWidth / 2}, 555);
        node.parentNode.scrollLeft = node.offsetLeft - node.parentNode.offsetWidth / 2;
      }
    }
  }, []);

  return <div>
    {topMenus.length > 1 ?
    <div className={'index-index ' + (topMenus[routerIndex].invasion ? 'invasion' : '')}>
    <div className="index-head width">
      <div className="background" style={{opacity: navTitle == 'oneGame' ? (newopacity !== undefined ? newopacity : 1) : navTitle == 'newGame' ? (travelopacity !== undefined ? travelopacity : 1) :  (opacity !== undefined ? opacity : 1) }}/>
      <div className="container">
        <div className="menu-list" style={{
          width:(opacity == 1 || travelopacity == 1 || routerIndex > 1 ? 'calc(100% - 85px)' : 'calc(100% - 53px)')
        }}>
          {topMenus.map((item, index) => 
          <div key={index} className={'item' + (item.is_pic ? ' pic-item' : '') + (index === routerIndex ? ' active' : '' ) + (item.id === 'new' && !badge[item.id] ? (' badge-' + item.id) : '') + ((opacity !== undefined && opacity !== 0) || (newopacity ? (newopacity !== undefined && newopacity !== 0):'') || (travelopacity ? (travelopacity !== undefined && travelopacity !== 0):'') || (navTitle !='recommend' && navTitle !='oneGame' && navTitle != 'newGame') ? ' item-opacity' : '')} onClick={() => {
            console.log("aaa",item)
            Manager.push({event_id:item.event_id})
            if(item.type == 'active') {
              // if(general.isLogin()){
                setRouterIndex(index);
                setNavTitle(item.type);
              // }
            } else {
              setRouterIndex(index);
              setNavTitle(item.type);
            }
            
            badge[item.id] = true;
            setBadge(badge);
            reHrefLoad(item);

            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['ClickTheTopTabOfHomePage', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), name:item.title, agent: agent}]
            });
          }}
          ref={routerIndex == index ? tagRef : null}
          >
            {(()=>{
              if(item.is_pic == true) {
                return <img src={item.big_title_img} className="index-nav-pic" />
              } else {
                return item.title;
              }
            })()}
            {/* {item.title} */}
            </div>
          )}
        </div>
        <div className="operate">
          {(opacity == 1 || travelopacity == 1 || routerIndex > 1) && 
            <Link to={'/search'} className={'index-header-search '}>
              <img src="/resources/index/index-search-1.png" className="black" />
            </Link>}
          <NavLink onClick={()=>{
            Manager.push({event_id:'C-5'})
          }} user={true} to={'/user/game'} className={'index-header-custom'}>
            <img style={{width:'23px'}} src="/resources/index/mygame.png" className="black" />
          </NavLink>
          {/* <Link to={'/service'} className={'index-header-custom ' + ((opacity !== undefined && opacity !== 0) || (newopacity?(newopacity !== undefined && newopacity !== 0):'') || (travelopacity ? (travelopacity !== undefined && travelopacity !== 0):'')  || (navTitle !='recommend' && navTitle !='oneGame') ? 'serviceOperates' : 'serviceOperates')}>
            <img src="/resources/index/index-service-2.png" className="white" />
            <img src="/resources/index/index-service-1.png" className="black" />
          </Link> */}
          {/* <Link to={'/search'} className={'index-header-search ' + ((opacity !== undefined && opacity !== 0) || (newopacity?(newopacity !== undefined && newopacity !== 0):'') || (navTitle !='recommend' && navTitle !='oneGame') ? 'searchOperates' : 'whiteSearch')}> */}
          {/* {routerIndex === 2 ? <div ref={ref} onClick={e => setOperate(!operate)}></div> : <Link to={'/search'}>
            <SearchOutlined className="search"/>
          </Link>} */}
        </div>
      </div>
    </div>
    <div className="index-body">
      {/** keepScroll 是2022-11-08新加的 之前没有 **/}
      <KeepScroll active={props.active}>
      {topMenus.map((item, index) => <div key={index} className={'index-body-item ' + (index === routerIndex ? 'active' : '')}>
        {(()=>{
          if(item.type == 'recommend') {
            return <Good  active={props.active && index === routerIndex}
            setOpacity={setOpacity}
            operate={operate}
            setOperate={setOperate}
            valueInfo={item.value}/>
          } else if(item.type == 'goodSelected') {
            return <Good  active={props.active && index === routerIndex}
            setOpacity={setOpacity}
            operate={operate}
            setOperate={setOperate}
            valueInfo={item.value}/>
          } else if(item.type == 'topGame') {
            return <Rank  active={props.active && index === routerIndex}
            setOpacity={setOpacity}
            operate={operate}
            setOperate={setOperate}
            valueInfo={item.value}/>
          } else if(item.type == 'oneGame') {
            return <Single  active={props.active && index === routerIndex}
            setOpacity={setOpacity}
            setNewOpacity={setNewOpacity}
            newopacity={newopacity}
            operate={operate}
            setOperate={setOperate}
            valueInfo={item.value}/>
          } else if(item.type == 'special') {
            if(item.style == '6') {
              return <Beta active={props.active && index === routerIndex}
              setOpacity={setOpacity}
              setNewOpacity={setNewOpacity}
              newopacity={newopacity}
              operate={operate}
              setOperate={setOperate}
              valueInfo={item.value}/>
            } else {
              return <Project active={props.active && index === routerIndex}
              setOpacity={setOpacity}
              setNewOpacity={setNewOpacity}
              newopacity={newopacity}
              operate={operate}
              setOperate={setOperate}
              valueInfo={item.value}/>
            }
          } else if(item.type == 'active') {
            return <Active active={props.active && index === routerIndex}
            setOpacity={setOpacity}
            setNewOpacity={setNewOpacity}
            newopacity={newopacity}
            operate={operate}
            setOperate={setOperate}
            valueInfo={item.value}/>;
          } else if(item.type == 'newGame'){
            // return <New  active={props.active && index === routerIndex}
            // setOpacity={setOpacity}
            // operate={operate}
            // setOperate={setOperate}
            // valueInfo={item.value}/>
            return <NewGame  active={props.active && index === routerIndex}
            setOpacity={setOpacity}
            setTravelOpacity={setTravelOpacity}
            travelopacity={travelopacity}
            operate={operate}
            setOperate={setOperate}
            valueInfo={item.value}/>
          } else if(item.type == 'video'){
            return <Video  active={props.active && index === routerIndex}
            setOpacity={setOpacity}
            operate={operate}
            setOperate={setOperate}
            valueInfo={item.value}/>
          } else {
            return;
          }
        })()}

        {/* <item.component
            active={props.active && index === routerIndex}
            setOpacity={setOpacity}
            operate={operate}
            setOperate={setOperate}
        /> */}
      </div>)}
      </KeepScroll>
    </div>
  </div>
  :null}
  </div>;
};